import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import img1 from '../assets/asta.jpg';
import img2 from '../assets/atulyabharat.jpg';
import img3 from '../assets/award1.jpg';
import img4 from '../assets/gst.png';
import img5 from '../assets/Udyam.jpg';

function Association(props) {

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 4
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2
    }
  };

  return(
        <div className='container mt-5 mb-3'>
            <Carousel responsive={responsive} infinite={true} autoPlay={true} showDots={false} draggable={true} swipeable={false} autoPlaySpeed={3000}>
              <img className='association-img' src={img1} />
              <img className='association-img' src={img4} />
              <img className='association-img' src={img2} />
              <img className='association-img ' src={img3} />
              <img className='association-img' src={img5} />
            </Carousel>
        </div>
  )
}

export default Association;